import React from "react"
import {
  Layout,
  Inner,
  List,
  FlexRow,
  Parallax,
  NewsletterSection,
} from "@components/layout"
import { Breadcrumbs } from "@components/shared"
import { PostItem, CategoryItem } from "@components/blog"

import { posts__wrapp } from "./styles/categories.module.scss"

const Posts = ({ posts }) => {
  const shuffledPosts = posts.sort(() => Math.random() - 0.5)

  return (
    <React.Fragment>
      {/* 			
				<h4>Data dla posts:{JSON.stringify(posts, null, 4)}</h4>
			 */}
      {shuffledPosts.map((el, k) => (
        <PostItem {...el} key={k} />
      ))}
    </React.Fragment>
  )
}

const SingleCategory = ({ pageContext }) => {
  const { posts, page, categories } = pageContext
  console.log('singleCategory')
  return (
    <Layout seo={page.seo} siteMetaData={page.siteMetaData}>
      <Parallax height={500} image={page.featuredImage}>
        <h1 dangerouslySetInnerHTML={{ __html: page.name }}></h1>
        <h3 dangerouslySetInnerHTML={{ __html: page.description }}></h3>
      </Parallax>
      <Breadcrumbs
        elements={[
          {
            label: "Blog",
            url: `/blog/`,
          },
          {
            label: page.name,
            url: `/blog/${page.slug}/`,
          },
        ]}
      />
      <div className={posts__wrapp}>
        <Posts posts={posts} />
      </div>
      <NewsletterSection />
    </Layout>
  )
}

export default SingleCategory
