import React from "react"
import moment from "moment"
import {
  ResizedImage as Image,
  AnimatedSection,
  AnimatedLink,
} from "@components/shared"
import { withHtml } from "@components/logic"
import finger from "../../../components.refactored/standard/Home/icons/fingerprint-bg-white.png"
import {
  postItem,
  post,
  post__wrapper,
  post__content,
  post__date,
  post__wrapper__text,
  post__wrapper__text__img,
  post__wrapper__img,
  post__wrapper__img__wrap,
  post__tags,
  post__tags__wrap,
} from "../styles/postitem.module.scss"

const Title = withHtml("h3")
const Description = withHtml("article")

const formatDate = (date) => {
  const formated = new Date(date)
  return `${formated.getUTCDate()}.${formated.getUTCMonth()}.${formated.getUTCFullYear()}`
}

const Tags = ({ tags }) => {
  const { nodes } = tags
  return (
    <div className={post__tags__wrap}>
      {nodes.map(({ slug, name }, i) => {
        return (
          <AnimatedLink to={`/tags/${slug}/`} key={i}>
            {"#" + name}
          </AnimatedLink>
        )
      })}
    </div>
  )
}

const PostItem = ({ title, slug, date, featuredImage, tags, categories }) => {
  return (
    <div className={postItem}>
      <AnimatedSection as={"article"} className={post__wrapper}>
        <AnimatedLink to={`/blog/${slug}/`} className={post}>
          <div className={post__wrapper__img}>
            <Image
              {...featuredImage}
              className={post__wrapper__img__wrap}
              size={"600w"}
            />
          </div>
          <div className={post__wrapper__text}>
            <div className={post__date}>{moment(date).format("DD.MM.YYYY")}</div>
            <div className={post__content}>
              <Title>{title}</Title>
            </div>
            <Tags tags={tags} className={post__tags} />
            <div className={post__wrapper__text__img}>
              <img src={finger} alt="" />
            </div>
          </div>
        </AnimatedLink>
      </AnimatedSection>
    </div>
  )
}
export default PostItem
