import React from "react"
import {
  ResizedImage as Image,
  AnimatedSection,
  AnimatedLink,
} from "@components/shared"

import { withHtml } from "@components/logic"

import {
  wrapp,
  category,
  category__wrapper,
  category__img,
} from "../styles/categoryitem.module.scss"

const Title = withHtml("h3")
const Description = withHtml("article")

const CategoryItem = ({ name, slug, description, featuredImage, allPosts }) => {

  // calculate category posts length from all posts list by slug
  const categoryPosts = allPosts.filter((el) => el.categories.nodes[0].slug == slug)
  const postsCount = categoryPosts.length
  
  return (
    <AnimatedLink
      to={`/blog/categories/${slug}/`}
      className={category__wrapper}
    >
      <div className={category}>
        <AnimatedSection className={wrapp}>
          <Title>{name}</Title>
          <Description>{description}</Description>

          <div className={category__img}>
            {/* <Image {...featuredImage}/> */}
            <h5>Artykułów: {postsCount}</h5>
          </div>
        </AnimatedSection>
      </div>
    </AnimatedLink>
  )
}

export default CategoryItem
