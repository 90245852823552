import { WPApi, PayUApi } from "@api"

const commentPost = ({postId, author, email, comment}) => {
    console.log(postId)
    return WPApi.commentPost(postId, author, email, comment).then(data => {
        return data
    })
}

const retrievePostComments = (postId) => {
    return WPApi.retrievePostComments(postId).then(data => {
        return data
    })
}


export { commentPost, retrievePostComments }